::-moz-selection {
    color: white;
    background: $accent-color;
}

::selection {
    color: white;
    background: $accent-color;
}

a, a:hover {
    text-decoration: none !important;
}

.post-excerpt:after {
    content: " leggi tutto";
}

.action {
    cursor: pointer;
}

.action-bar {
    padding-top: 3rem;
}

.panel-group {
    padding: 1rem;
    background-color: $panel-group-color;
    box-shadow: 0 1px 1px $shadow-color;
}

.panel-group .action-bar {
    padding-top: 2rem;
}

.pre-footer-group {
    background-color: $panel-group-color;
}

.h-deco {
    border-top: 2px solid $accent-color;
}

.my-quote {
    border-left: $accent-color 2px solid;
    padding-left: 1.5rem;
}

.comma-separator:after,
.single-separator:before {
  color: $dark-text-color;
  content: ", ";
}

.form-control {
  border-radius: 0;
  border: none;
  background-color: $light-back-color;
}
