@import 'variables.scss';
@import 'fonts.scss';
@import 'common.scss';

body {
  font-family: 'Noway Regular';
  color: $dark-text-color;
}

.hero {
  width: 100%;
  height: 100%;
  background-image: url('../assets/images/hero.jpg');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; 
}

.gway-logo {
    height: 86px;
}

.headline {
  background-color: $accent-color;
}

.headline h1 {
  font-family: 'Noway Regular';
  font-size: 2rem;
  line-height: 1.1875;
  margin: 0;
  color: $reverse-text-color;
  padding: 1rem;
}

h1 {
  font-size: 2rem;
  line-height: 1.333;
  margin-bottom: 2rem;
}

.panel-group h1 {
  font-size: 1.25rem;
  line-height: 1.333;
  margin-bottom: 1.25rem;
}

.enterprise-group h1 {
	font-size: 1.25rem;
	line-height: 1.4;
	margin-bottom: 1.25rem;
}

.group h1,
.my-quote h1,
.blog-index-group h1,
.page-content h1 {
  font-size: 1.5rem;
  line-height: 1.333;
  margin-bottom: 1.5rem;
}

h2 {
  font-family: 'Noway Regular';
  font-size: 1.5rem;
  line-height: 1.375;
  margin: 2.5rem 0 1.5rem 0;
}

form h5 {
  font-family: 'Noway Medium';
  font-size: 1.5rem;
  line-height: 1.375;
  margin-bottom: 1.5rem;
}

p {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 1rem;
}

ul {
  list-style: none;
  display: table;
  padding: 0;
  margin: 0;
}

ul.std-list {
  list-style: disc;
}

.std-list li {
  margin-bottom: 1rem;
}

.group .custom-list li {
	font-size: 1.5rem;
	list-style-type: none;
	background-image: url('../assets/images/star.svg');
	background-position: 0 10px;
	background-repeat: no-repeat;
	padding-left: 2rem;
}

.collapse-menu-filler {
  position: fixed;
  display: block;
  width: 80%;
  top: 0;
  right: -80%;
  bottom: 0;
  transition: all .25s;
}

.menu-social-bar {
    display: none !important;
    position: fixed;
	z-index: 997;
	top: unset;
	left: 0;
	bottom: 0;
	right: 0;
}

@media (min-width: 576px) {
	
	.collapse-menu-filler {
		position: fixed;
		display: block;
		width: 60%;
		top: 0;
		right: -60%;
		bottom: 0;
		transition: all .25s;
	}

	.menu-social-bar {
		position: fixed;
		top: 45%;
		left: 22px;
		bottom: unset;
		right: unset;
		transform: translateX(-50%) rotate(-90deg);
		z-index: 999;
	}
}

@media (min-width: 768px) {
  .headline {
    background-color: $accent-color;
  }
  
  .headline h1 {
    font-family: 'Noway Regular';
    font-size: 4rem;
    line-height: 1.1875;
    margin: 0;
    color: $reverse-text-color;
    padding: 1rem;
  }
  
  h1 {
    font-size: 3rem;
    line-height: 1.333;
    margin-bottom: 1.5rem;
  }

	.panel-group h1 {
		font-size: 1.25rem;
		line-height: 1.333;
		margin-bottom: 1.25rem;
	}
	
	.group h1,
	.my-quote h1,
	.blog-index-group h1 {
		font-size: 2rem;
		line-height: 1.333;
		margin-bottom: 2rem;
	}
	
	.page-content h1 {
		font-size: 2rem;
		line-height: 1.333;
		margin-bottom: 2rem;
	}

	.enterprise-group h1 {
		font-size: 3rem;
		line-height: 1.333;
		margin: 2rem 0;
	}
  
  h2 {
    font-family: 'Noway Regular';
    font-size: 2rem;
    line-height: 1.375;
    margin: 3rem 0 2rem 0;
  }
	
	.std-list li,
  p {
    font-size: 1.25rem;
    line-height: 1.6;
    margin-bottom: 1.25rem;
  }
	
	.product p {
    font-size: 1rem;
    line-height: 1.6;
    margin: 0;
	}
	
  .panel-group p,
  .my-quote p {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 1rem;
	}

	.group .custom-list li {
		font-size: 1.5rem;
		list-style-type: none;
		background-image: url('../assets/images/star.svg');
		background-position: 0 10px;
		background-repeat: no-repeat;
		padding-left: 2rem;
	}
	
	.collapse-menu-filler {
		position: fixed;
		display: block;
		width: 50%;
		top: 0;
		right: -50%;
		bottom: 0;
		transition: all .25s;
	}
}

@media (min-width: 992px) {
	.collapse-menu-filler {
		position: fixed;
		display: block;
		width: 40%;
		top: 0;
		right: -40%;
		bottom: 0;
		transition: all .25s;
	}
}

@media (min-width: 1200px) {

}

// Non breaking-point classes
.btn-primary, 
.btn-primary:not(:disabled):not(.disabled).active, 
.btn-primary:not(:disabled):not(.disabled):active {
  border-radius: 0;
  text-transform: uppercase;
  background-color: $accent-color;
  border-color: $accent-color;
  font-family: 'Noway Medium';
  font-size: 1.25rem;
  box-shadow: 0 1px 1px $shadow-color;
}

.btn-primary:hover {
  background-color: $dark-text-color;
  border-color: $dark-text-color;
  font-family: 'Noway Medium';
  font-size: 1.25rem;
  box-shadow: 0 1px 1px $shadow-color;
}

.enterprise-img {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-image: url('../assets/images/enterprise.jpg');
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	opacity: .5;
}

.enterprise-group {
	position: relative;
}

.gov-way-link {
	color: $dark-text-color;
  font-size: 1rem;
	font-family: 'Noway Medium';
}

.gov-way-link:hover,
.gov-way-link:hover i {
	color: $accent-color;
	font-family: 'Noway Medium';
}

.excerpt-link {
	color: $accent-color;
	font-family: 'Noway Medium';
}

.excerpt-link:hover,
.post-excerpt:hover,
.gov-way-link {
	color: $dark-text-color;
}

.group .excerpt-link {
	font-size: 1.5rem;
}

.group .excerpt-link:hover {
  color: $dark-text-color;
}

.post-notizia h1 a,
.post-aggiornamento h1 a,
.blog-index-group h1 a {
  color: $dark-text-color;
}

.post-notizia h1 a:hover,
.post-aggiornamento h1 a:hover,
.blog-index-group h1 a:hover {
  color: $accent-color;
}

.post-notizia h1 {
	font-size: 2rem;
	line-height: 1.5;
	margin-bottom: 2rem;
}

.post-aggiornamento h1 {
	font-size: 1.5rem;
	line-height: 1.5;
	margin-bottom: 1.5rem;
}

.post-notizia p,
.post-aggiornamento p,
.blog-index-group p {
	font-size: 1rem;
	line-height: 1.6;
	margin-bottom: 1rem;
}

.post-meta {
	font-size: 1rem;
	line-height: 1.6;
	margin-bottom: 1rem;
  color: $light-text-color;
}

// Navbar
.navbar-brand img {
  height: 86px;
  width: auto;
}

//Pre-footer
.blurb-logos {
  height: 86px;
  width: auto;
  margin-bottom: 1rem;
}

// Footer
.footer-link {
  padding: 3rem 0 5rem 0;
  background-color: $dark-text-color;
  color: $footer-text-color;
}

.footer-link p {
  font-size: .875rem;
  line-height: 1.428;
  margin-bottom: 0;
}

.lnk-logo {
  width: auto;
  height: 48px;
}

.fa-github-alt:before {
  content: "\f113";
}
 
.fa-linkedin-in:before {
  content: "\f0e1";
}
 
.fa-envelope-o:before {
  content: "\f0e0";
}
 
.fa {
  display: inline-block;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  font-size: 24px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
 
a .fa {
  color: $footer-text-color;
}

a:hover .fa {
  color: $accent-color;
}

// Nav
.active-page {
  color: $accent-color !important;
}

.collapse-menu {
  background-color: $bkg-color;
  padding: 2rem 2rem;
  box-shadow: -1px 0px 2px 0px rgba(33, 33, 33, 0.16);
  z-index: 999;
}

.over-layer {
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255,255,255,0);
  z-index: 998;
}

.show ~ .over-layer {
  display: block;
}

.show {
  transition: all .25s;
  transform: translateX(-100%);
}

.side-menu {
	line-height: 1.375;
	font-size: 2rem;
	margin-bottom: 1.5rem;
}

.side-menu a {
	font-size: 2rem;
  color: $dark-text-color;
}
