@font-face {
    font-family: 'Noway Regular';
    src: url('./fonts/noway-regular-webfont.eot');
    src: url('./fonts/noway-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('./fonts/noway-regular-webfont.woff2') format('woff2'),
         url('./fonts/noway-regular-webfont.woff') format('woff'),
         url('./fonts/noway-regular-webfont.ttf') format('truetype'),
         url('./fonts/noway-regular-webfont.svg#nowayregular') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'Noway Medium';
    src: url('./fonts/noway-medium-webfont.eot');
    src: url('./fonts/noway-medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('./fonts/noway-medium-webfont.woff2') format('woff2'),
         url('./fonts/noway-medium-webfont.woff') format('woff'),
         url('./fonts/noway-medium-webfont.ttf') format('truetype'),
         url('./fonts/noway-medium-webfont.svg#nowaymedium') format('svg');
    font-weight: normal;
    font-style: normal;

}

/* Material icons */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/MaterialIcons-Regular.eot');
  src: url('./fonts/MaterialIcons-Regular.eot?#iefix') format('embedded-opentype'),
       url('./fonts/MaterialIcons-Regular.woff2') format('woff2'),
       url('./fonts/MaterialIcons-Regular.woff') format('woff'),
       url('./fonts/MaterialIcons-Regular.ttf') format('truetype'),
       url('./fonts/MaterialIcons-Regular.svg#nowayregular') format('svg');
}

.material-icons {
     color: rgba(33, 33, 33, .6);
     font-family: 'Material Icons' !important;
     font-weight: normal;
     font-style: normal;
     font-size: 36px;
     line-height: 1;
     letter-spacing: normal;
     text-transform: none;
     display: inline-block;
     white-space: nowrap;
     word-wrap: normal;
     direction: ltr;
     -moz-font-feature-settings: 'liga';
     -moz-osx-font-smoothing: grayscale;
     color: $dark-text-color;
}

/*Fontawesome*/
@font-face {
     font-family: 'FontAwesome';
     src: url('./fonts/fontawesome-webfont.eot');
     src: url('./fonts/fontawesome-webfont.eot?#iefix') format('embedded-opentype'),
        url('./fonts/fontawesome-webfont.woff2') format('woff2'),
        url('./fonts/fontawesome-webfont.woff') format('woff'),
        url('./fonts/fontawesome-webfont.ttf') format('truetype'),
        url('./fonts/fontawesome-webfont.svg#nowaymedium') format('svg');
     font-weight: normal;
     font-style: normal;
}